import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import PopularPromoItemsImg from "../../components/images/promotional/water-bottle"

export default function PopularPromoItems() {
    return (
        <>
            <Layout>
                <SEO title="Popular Promo Items" />
                <Display
                    title="Popular Promo Items"
                    description="Want to know the latest in trending items that will have lasting impact? Our experienced team can help you choose the best products for your budget."
                    prev="/promotional/office-supplies"
                    next="/promotional/tradeshows-events"
                    image={<PopularPromoItemsImg />}
                />
            </Layout>                
        </>
    )
}
